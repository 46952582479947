export type SKU = {
  id: string,
  sku: string,
  altId?: string,
  altSku?: string
}

type Product = {
  single: SKU | null,
  subscription: SKU | null
}

type SkuName = "hydroLight25" | "hydroLight40" | "niacinaGlow" | "peelCare10" | "peelCare20" | "peelCare30" | "retinoDermo01" | "retinoDermo02" | "retinoDermo04" | "skinCycleMethod3PieceSet" | "freeSelect"
export const productSku: Record<string & SkuName, Product> = JSON.parse(document.getElementById('sku-map2')?.textContent ?? '')