import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { SectionHead } from '../components/section-header'
import { AssetImage } from '../components/image'
import { SlideWithNavigator } from '../components/slide-with-navigator'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { AnimateArrow } from '../components/animate-arrow'
import { productSku } from '../product-sku'
import { skuToUrl } from '../utils/sku-to-url'
import { BoxText } from '../components/box-text'

const Outer = styled.div`
  overflow: hidden;
`

const Inner = styled.div`
  ${pageMaxWidth}
`

const HeadContainer = styled.div``

const SliderContainer = styled.div`
  margin-left: 5rem;
  margin-right: calc(var(--side-margin) * -1);
  @media (max-width: 40rem){
    margin-left: 0;
  }
`

const Label = styled.div`
  font-weight: 400;
`

const Item = styled.a`
  width: 24rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    width: 18rem;
  }
`

const Title = styled.div`
  font-size: 1.375rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-family: var(--en-font);
  @media (max-width: 40rem){
    font-size: 1.0625rem;
  }
`

const Text1 = styled.div`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  letter-spacing: 0.06em;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }

`

const ImageContainer = styled.div`
  margin-top: 1.5rem;
  overflow: hidden;
  position: relative;
  @media (max-width: 40rem){
    margin-top: 1rem;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.4s;
    ${Item}:hover & {
      transform: scale(1.05);
    }
  }
`

const Text2 = styled.div`
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 40rem){
    margin-top: 1.5rem;
  }
`

const Text3 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    margin-top: 1rem;
    font-size: 0.875rem;
  }
  small {
    font-size: 0.8em;
  }
`

const ViewMoreButton = styled.div`
  display: flex;
  margin-top: 4rem;
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: 0.08em;
  justify-content: space-between;
  font-family: var(--serif-en-font);
  font-weight: 400;;
  align-items: flex-end;
  transition: opacity 0.4s 0.2s;
  opacity: 0;
  .animate & {
    opacity: 1;
  }
  @media (max-width: 40rem){
    margin-top: 2.5rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    margin-right: 1rem;
    transition: transform 0.4s 0.4s;
    transform: translateX(-1rem);
    .animate & {
      transform: none;
    }
  }
  ${Item}:hover & svg {
    transition-delay: 0s;
    transform: translateX(0.5rem);
  }
`

const Text4 = styled.div`
  margin-top: 3rem;
  font-size: 0.5625rem;
  line-height: normal;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
    margin-top: 1.5rem;
  }
`

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`

const ImageWrapper = styled.div`
  position: relative;
`

const Badge = styled.div`
  position: absolute;
  font-family: var(--en-font);
  line-height: normal;
  width: 6rem;
  height: 6rem;
  top: 0;
  right: -1rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20rem;
  color: white;
  text-align: center;
  /* background-image: linear-gradient(to right bottom, #B7CEB4, #BFC2C7, #C6B7D8); */
  box-sizing: border-box;
  padding-top: 0.5rem;

  background-color: #d5c192;
  color: white;
  font-size: 0.8rem;
  padding-top: 0;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    width: 5rem;
    height: 5rem;
  }
`

type TopLineupSectionProps = {
  showSet?: boolean
}
export const TopLineupSection: FunctionComponent<TopLineupSectionProps> = ({showSet}) => {
  const {ref, swiper} = useSwiperClass()
  return <Outer>
    <Inner>
      <HeadContainer>
        <SectionHead>Line Up</SectionHead>
      </HeadContainer>
      <SliderContainer>
        <SlideWithNavigator
          onSwiper={ref}
          items={[
            ...(showSet ? [
              {
                label: <Label>
                  SKIN CYCLE METHOD<br />
                  3 PIECE SET
                </Label>,
                content: <Item href={skuToUrl(productSku.skinCycleMethod3PieceSet.single)}>
                  <Title>
                    SKIN CYCLE METHOD<br />3 PIECE SET
                  </Title>
                  <Text1>
                    スキンサイクルメソッド3点セット
                  </Text1>
                  <ImageWrapper>
                    <ImageContainer>
                      <AssetImage src="product-08.png" />
                    </ImageContainer>
                  </ImageWrapper>
                  <Text2>
                    スキンサイクルメソッド3点セット
                  </Text2>
                  <Text3>
                    スキンサイクルメソッドが完成するSKN REMEDの3点セット。ピールケアセラム、レチノダーマクリーム、ナイアシナグロージェルが入ったお得なセットです。
                  </Text3>
                  <Animate target="child">
                    <ViewMoreButton>
                      VIEW MORE
                      <AnimateArrow>
                        <SvgThinRightArrow />
                      </AnimateArrow>
                    </ViewMoreButton>
                  </Animate>
                  <Text4>
                    
                  </Text4>
                </Item>
              },
              // {
              //   label: <Label>
              //     FREE SELECT
              //   </Label>,
              //   content: <Item href={skuToUrl(productSku.freeSelect.single)}>
              //     <Title>
              //       FREE SELECT<br />
              //       &nbsp;
              //     </Title>
              //     <Text1>
              //       フリーセレクト
              //     </Text1>
              //     <ImageContainer>
              //       <AssetImage src="product-08.png" />
              //     </ImageContainer>
              //     <Text2>
              //       フリーセレクト
              //     </Text2>
              //     <Text3>
              //       欲しい種類やグレードを、自由に組み合わせて購入するならこちら。
              //     </Text3>
              //     <Animate target="child">
              //       <ViewMoreButton>
              //         VIEW MORE
              //         <AnimateArrow>
              //           <SvgThinRightArrow />
              //         </AnimateArrow>
              //       </ViewMoreButton>
              //     </Animate>
              //     <Text4>
                    
              //     </Text4>
              //   </Item>
              // }
            ] : []),
            {
              label: <Label>
                <span>PeelCare</span><br />
                Serum
              </Label>,
              content: <Item href={skuToUrl(productSku.peelCare10.single)}>
                <Title>
                  PeelCare Serum<br />&nbsp;
                </Title>
                <Text1>
                  角質ケア
                </Text1>
                <ImageContainer>
                  <AssetImage src="product-01.png" />
                </ImageContainer>
                <Text2>
                  ピールケアセラム(1.0/2.0/3.0)
                </Text2>
                <Text3>
                  角質ケア成分<sup>※1</sup>配合の導入美容液。成分、使用感で選べる3段階のピールケアセラムで、ゴワつきのないなめらかな素肌へ導きます。<br />
                </Text3>
                <Animate target="child">
                  <ViewMoreButton>
                    VIEW MORE
                    <AnimateArrow>
                      <SvgThinRightArrow />
                    </AnimateArrow>
                  </ViewMoreButton>
                </Animate>
                <Text4>
                  ※1 乳酸、ライム果汁、オレンジ果汁、レモン果汁、サンザシエキス、ナツメ果実エキス、グレープフルーツ果実エキス、リンゴ果実エキス、マンデル酸、グリコール酸(フルーツピールエキスは1.0にのみ、グリコール酸は2.0、 3.0にのみ配合)(すべて整肌成分)
                </Text4>
              </Item>
            },
            {
              label: <Label>
                <span>RetinoDerm™</span><br />
                Cream
              </Label>,
              content: <Item href={skuToUrl(productSku.retinoDermo01.single)}>
                <Title>
                  RetinoDerm™ Cream<br />&nbsp;
                </Title>
                <Text1>
                  集中ケア
                </Text1>
                <ImageContainer>
                  <AssetImage src="product-02.png" />
                </ImageContainer>
                <Text2>
                  レチノダーマクリーム(0.1/0.2/0.4)
                </Text2>
                <Text3>
                  ピュアレチノール<sup>※1</sup>配合の美容クリーム。成分作用と肌への負担、どちらも重視し厳選された5種類の成分を組み合わせた独自のレチノブレンド<sup>※2</sup>を配合。ハリとツヤに溢れる肌に仕上げます。
                </Text3>
                <Animate target="child">
                  <ViewMoreButton>
                    VIEW MORE
                    <AnimateArrow>
                      <SvgThinRightArrow />
                    </AnimateArrow>
                  </ViewMoreButton>
                </Animate>
                <Text4>
                  ※1 レチノール（整肌成分）※2 バクチオール、パルミチン酸レチノール、レチノイン酸トコフェリル、レチノール、レチノイルヒアルロン酸Ｎａ（バクチオールは0.1にのみ、レチノイルヒアルロン酸Ｎａは0.2、 0.4にのみ配合）すべて整肌成分
                </Text4>
              </Item>
            },
            {
              label: <Label>
                <span>HydroLight™</span><br />
                Cream
              </Label>,
              content: <Item href={skuToUrl(productSku.hydroLight25.single)}>
                <Title>
                  HydroLight™ Cream<br />&nbsp;
                </Title>
                <Text1>
                  集中ケア
                </Text1>
                <ImageContainer>
                  <AssetImage src="product-03.png" />
                </ImageContainer>
                <Text2>
                  ハイドロライトクリーム(2.5/4.0)
                </Text2>
                <Text3>
                  選べる2段階のハイドロキノン<sup>※1</sup>グレードで、透明感のある明るい肌へ。4種のバリアケアブレンド<sup>※2</sup>もプラスすることで、乾燥から肌を守りながら健やかな肌に仕上げます。
                </Text3>
                <Animate target="child">
                  <ViewMoreButton>
                    VIEW MORE
                    <AnimateArrow>
                      <SvgThinRightArrow />
                    </AnimateArrow>
                  </ViewMoreButton>
                </Animate>
                <Text4>
                  ※1 ハイドロキノン(整肌成分) 　※2 アラントイン、ツボクサエキス、マデカッソシド、グリチルリチン酸２Ｋ（すべて整肌成分）
                </Text4>
              </Item>
            },
            {
              label: <Label>
                <span>NiacinaGlow™</span><br />
                Gel
              </Label>,
              content: <Item href={skuToUrl(productSku.niacinaGlow.single)}>
                <Title>
                  NiacinaGlow™ Gel<br />&nbsp;
                </Title>
                <Text1>
                  保湿ケア
                </Text1>
                <ImageContainer>
                  <AssetImage src="product-04.png" />
                </ImageContainer>
                <Text2>
                  薬用ナイアシナグロージェル<BoxText>医薬部外品</BoxText>
                </Text2>
                <Text3>
                  美白<sup>※1</sup>、シワ改善効果を持つナイアシンアミド<sup>※2</sup>と肌荒れを防ぐグリチルリチン酸ジカリウム<sup>※2</sup>を配合した保湿ジェル。<br />集中ケア後のおやすみ期間中に肌を労わるケアで、潤いバリアをサポートします。<br />
                  <br />
                  販売名：ＳＲ　薬用Ｎジェル
                </Text3>
                <Animate target="child">
                  <ViewMoreButton>
                    VIEW MORE
                    <AnimateArrow>
                      <SvgThinRightArrow />
                    </AnimateArrow>
                  </ViewMoreButton>
                </Animate>
                <Text4>
                  ※1 メラニンの生成を抑え、シミ・そばかすを防ぐ　※2 有効成分
                </Text4>
              </Item>
            },
          ]}
        />
      </SliderContainer>
    </Inner>
  </Outer>
}