import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { pageMaxWidthWithPadding } from '../styles/page-max-width'
import SvgShipFree from '../generated/svg/ship-free'
import SvgMoney from '../generated/svg/money'
import SvgCalender from '../generated/svg/calender'

const Outer = styled.div`
  ${pageMaxWidthWithPadding}
`

const Inner = styled.div`
  padding: 4.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text1 = styled.div`
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 700;
  text-align: center;
`

const Container = styled.div`
  display: flex;
  gap: 4rem;
  margin-top: 3rem;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const Item = styled.div`

`

const IconContainer = styled.div`
  svg {
    display: block;
    height: 4.6875rem;
    width: auto;
  }
`

const Label = styled.div`
  line-height: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  text-align: center;
  margin-top: 2.5rem;
`

type SubscriptionBannerSectionProps = {
  color: string
}
export const SubscriptionBannerSection: FunctionComponent<SubscriptionBannerSectionProps> = ({color}) => {
  return <Outer>
    <Inner style={{backgroundColor: color}}>
      <Text1>
        続けて嬉しい定期コース
      </Text1>
      <Container>
        <Item>
          <IconContainer>
            <SvgMoney />
          </IconContainer>
          <Label>特別価格</Label>
        </Item>
        <Item>
          <IconContainer>
            <SvgShipFree />
          </IconContainer>
          <Label>全国送料無料</Label>
        </Item>
        <Item>
          <IconContainer>
            <SvgCalender />
          </IconContainer>
          <Label>配送日設定可能</Label>
        </Item>
      </Container>
      <Text2>
        定期コースは、ずっと特別価格＆送料無料で定期的に商品をお届けいたします。<br />
        注文の手間が省けて買い忘れも防ぐことができ、お得に継続できるコースです。<br />
        スキンサイクルメソッドをしっかり体感したい方、続けてお肌の様子を見たい方は、<br />
        ぜひお得な定期コースをおすすめいたします。
      </Text2>
    </Inner>

  </Outer>
}